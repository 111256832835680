<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Adicionar Escola">
      <form-school action="add"></form-school>
    </base-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    page: {
      title: 'Escolas'
    },
    id: '',
    sending: false,
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        href: '/'
      },
      {
        text: 'Escolas',
        disabled: false,
        href: '/school'
      },
      {
        text: 'Adicionar Escola',
        disabled: true,
        href: '/school/add'
      }
    ]
  })

}
</script>
